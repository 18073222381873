/** @jsx jsx */
import { jsx, Image, Heading, Text, Box } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import hello from "../images/hello-badge.png"

const BrandPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650, textAlign: "center" }}
        >
          <Image
            src={hello}
            width={638}
            height={468}
            alt=""
            mb={60}
            mx="auto"
            sx={{ width: "65%", display: "block" }}
          />

          <Heading sx={{ mb: 4 }}>About the brand</Heading>
          <Text variant="paragraph">
            Adam Monster was born to prove the importance of creativity in
            business. At Adam Monster we know that data is not the answer to
            every question and our focus is on solving problems with creativity
            and through storytelling.
          </Text>

          <Text variant="paragraph">
            Current Release: <Link to="/episode-002">Episode 002</Link>
          </Text>

          <Text variant="paragraph">
            Writer: Spencer Smith
            <br />
            Editor: Fiona Jensen
            <br />
            Head Developer: Ben Kannenberg
            <br />
            Photographer: Adam Mckay -{" "}
            <a
              href="https://www.adammckayphotography.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              website
            </a>
          </Text>

          <Text variant="paragraph">
            Adam Monster was started in Oct ’18, Henley Beach, Adelaide South
            Australia.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default BrandPage
